





































































































import Vue from 'vue';
import * as Api from '@/api/log';
import moment from 'moment';
const columns = [
  {
    title: '账号',
    dataIndex: 'creatorName',
    key: 'creatorName',
    align: 'left',
  },
  {
    title: '登录终端',
    dataIndex: 'userAgent',
    key: 'userAgent',
    align: 'center',
    width: '330px',
    scopedSlots: { customRender: 'userAgent' },
  },
  {
    title: 'IP',
    dataIndex: 'ip',
    key: 'ip',
    align: 'center',
  },
  {
    title: '所属系统',
    dataIndex: 'applicationName',
    key: 'applicationName',
    align: 'center',
  },
  {
    title: '登录状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '引流来源',
    dataIndex: 'loginChannel',
    key: 'loginChannel',
    scopedSlots: { customRender: 'loginChannel' },
  },
  {
    title: '操作信息',
    dataIndex: 'message',
    key: 'message',
    align: 'center',
  },
  {
    title: '操作时间',
    dataIndex: 'createDate',
    key: 'createDate',
    align: 'center',
    scopedSlots: { customRender: 'createDate' },
  },
];
const dataSource = [];
export default Vue.extend({
  name: 'projectList',
  props: {
    guideVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableLoading: false,
      formState: {
        iP: '',
        userAgent: '',
        loginChannel: '',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      dataSource,
      columns,
      applyTime: [],
    };
  },
  mounted() {
    this.getLoginLogList();
  },
  methods: {
    moment,
    getLoginLogList() {
      const params = {
        logType: 0,
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        startTime: this.applyTime[0],
        endTime: this.applyTime[1],
        createUser: this.formState.userAgent,
        ip: this.formState.iP,
        loginChannel: this.formState.loginChannel,
      };
      this.tableLoading = true;
      Api.sysLogResourceV1(params)
        .then((res) => {
          if (res.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getLoginLogList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getLoginLogList();
    },
    range(start, end) {
      const result: number[] = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },
  },
});

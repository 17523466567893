/*
 * @Descripttion:
 * @Author: wuxinxin
 * @Date: 2021-12-07 16:48:12
 * @LastEditors: wuxinxin
 * @LastEditTime: 2021-12-07 16:48:12
 */
import http from '@/utils/http';

//  日志列表查询
export function sysLogResourceV1(formdata: any): any {
  return http.get(`/sys-resource/1/sysLogResourceV1`, formdata);
}
